import { useCallback, useRef, useEffect, useMemo, memo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { cn, Scroller } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import useKeyboardEvents from '@Hooks/useKeyboardEvents';
import useMedias from '@Hooks/useMedias';
import toKebabCase from '@Utils/toKebabCase';
import Link from '@Navigation/Link';
import ModalBase from '../ModalBase/ModalBase';
import styles from './ModalMain.module.css';

import type { HTMLAttributes, FC, MouseEvent } from 'react';
import type { Modal } from '@Stores/ModalWindows/typings';

export interface ModalMainProps<T = unknown> extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  view?: 'success' | 'error';
  modal: Modal<T>;
  fullscreen?: boolean;
  isStretch?: boolean;
  navigation?: { nextHref: string; prevHref: string } | boolean;
  cnContentWrapper?: string;
  onNext?: (e: MouseEvent | KeyboardEvent) => void;
  onPrev?: (e: MouseEvent | KeyboardEvent) => void;
  onClose?: (e: MouseEvent | KeyboardEvent) => void;
}

interface ContainerProps {
  children: React.ReactElement;
}

const Container: FC<ContainerProps> = (props) => {
  const { children } = props;
  const { isMobileM } = useMedias();

  return isMobileM ? <>{children}</> : <Scroller className={styles.scroller}>{children}</Scroller>;
};

const ModalMain: FC<ModalMainProps> = (props) => {
  const {
    className,
    view,
    modal,
    fullscreen,
    isStretch,
    children,
    navigation,
    cnContentWrapper,
    onClose,
    onNext,
    onPrev,
    ...restProps
  } = props;
  const [visible, setVisible] = useState(false);
  const { isMobile } = useMedias();
  const modals = ModalWindows.useModals();
  const refWrapper = useRef();
  const id = toKebabCase(modal.id);

  const nextHref = useMemo(() => {
    if (typeof navigation === 'boolean') return null;

    return navigation?.nextHref || null;
  }, [navigation]);

  const prevHref = useMemo(() => {
    if (typeof navigation === 'boolean') return null;

    return navigation?.nextHref || null;
  }, [navigation]);

  const handleClickWrapper = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.target !== refWrapper.current) return;
      if (onClose) onClose(e);
    },
    [onClose],
  );

  useEffect(() => {
    // Обновляем состояние в следующем тике event loop, иначе анимация открытия модального окна
    // не запустится после hot-reload в режиме разработки
    const timeoutId = setTimeout(() => setVisible(modal.visible));

    return () => clearTimeout(timeoutId);
  }, [modal.visible]);

  useKeyboardEvents({ onEscape: onClose });

  return (
    <ModalBase id={id} view={view || 'success'}>
      <CSSTransition
        classNames={{
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          enter: styles.enter,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}
        in={visible}
        unmountOnExit={!modal.hidden}
        timeout={modals.timeout}
      >
        <div
          {...restProps}
          className={cn(
            styles.container,
            {
              [styles.fullscreen]: fullscreen,
              [styles.hidden]: modal.hidden,
              [styles.stretch]: isStretch,
            },
            className,
          )}
          data-testid={`${id}-modal`}
        >
          <Container>
            <div
              className={styles.wrapper}
              ref={refWrapper}
              onClick={handleClickWrapper}
              data-testid='modal-wrapper'
            >
              {navigation && !isMobile && (
                <Link
                  to={prevHref}
                  className={styles.prev}
                  preventDefault={typeof navigation === 'boolean'}
                  onClick={onPrev}
                >
                  <div className={cn(styles.arrowBackground, { [styles.prev]: true })}>
                    <div className={styles.arrow} />
                  </div>
                </Link>
              )}
              <div className={cn(styles.content, cnContentWrapper)}>{children}</div>
              {navigation && !isMobile && (
                <Link
                  to={nextHref}
                  className={styles.next}
                  preventDefault={typeof navigation === 'boolean'}
                  onClick={onNext}
                >
                  <div className={cn(styles.arrowBackground, { [styles.next]: true })}>
                    <div className={styles.arrow} />
                  </div>
                </Link>
              )}
            </div>
          </Container>
        </div>
      </CSSTransition>
    </ModalBase>
  );
};

export default memo(ModalMain);
